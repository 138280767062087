import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Organization } from '@organization/models/organization.model';
import { OrganizationService } from '@organization/services/organization.service';
import { Person, PersonTournamentFields } from '@person/models/person.model';
import { PersonTournamentFieldsService } from '@person/services/person-tournament-fields.service';
import { PersonService } from '@person/services/person.service';
import { TournamentRoleService } from '@person/services/tournament-role.service';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';
import { I18nHelper } from '@shared/helpers/i18n.helper';
import { RoleService } from '@tournament/services/role.service';
import { Country } from '@shared/interfaces/country.interface';
import { TournamentService } from '@tournament/services/tournament.service';
import { HotelRoomType } from '@hotel/enums/hotel-room-type.enum';
import { GenderHelper } from '../helpers/gender.helper';
import { Gender } from '@person/enums/gender.enum';
import { BadgeService } from '@badge/services/badge.service';
import { AccessTypeService } from '@badge/services/accessType.service';
import { RoleIdentifier } from '@person/enums/role.identifier.enum';

@Component({
  selector: 'app-person-edit',
  templateUrl: './person-edit.component.html',
  styleUrls: ['./person-edit.component.scss']
})
export class PersonEditComponent implements OnInit {

  @Input('goBackOnSubmit') goBackOnSubmit = true;
  @Input('showButtons') showButtons = true;
  @Input('showAvatar') showAvatar = true;
  @Input('inputSubtitles') inputSubtitles: any = null;
  @Input('isTournamentDetailsSectionVisible') isTournamentDetailsSectionVisible = false;

  formGroup = this.fb.group({
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    country: [null, [Validators.required]],
    email: ['', [Validators.email]],
    bwfId: ['', []],
    organization: [null, []],
    gender: [Gender.MALE, [Validators.required]],
    phone: ['', []],
    birthdate: [null, []],
    arrivesAt: [null, []],
    departsAt: [null, []],
    isParkingCardNeeded: [false, []],
    isParkingCardIssued: [false, []],
    isHotelNeeded: [false, []],
    isTransportNeededOnArrival: [false, []],
    isTransportNeededOnDeparture: [false, []],
    isFreeHotel: [false, []],
    isHotelInvoicePaid: [false, []],
    isVisaNeeded: [false, []],
    isBadgeNeeded: [false, []],
    // isPcrTestReceived: [false, []],
    // isAntigenTestReceived: [false, []],
    // pcrTestReceivedAt: [null, []],
    // antigenTestReceivedAt: [null, []],
    paidAt: [null, []],
    invoiceNumber: ['', []],
    preferredHotelRoomType: [HotelRoomType.SINGLE, []],
    roles: [null, []],
    accessTypesOfPerson: this.fb.array([]),
    comment: ['', []],
  });

  submitting = false;
  error = null;
  personId = null;
  humanFriendlyId = null;
  avatarUrl = null;
  image: File = null;
  tournament;
  tournamentFields: PersonTournamentFields;
  badges = [];
  accessTypes = [];

  countries: Country[] = I18nHelper.getAllCountries();
  genders = GenderHelper.genders;
  tournaments = [];
  organizations = [];
  rolesOfPerson = [];
  rolesOfTournament = [];

  hotelRoomType: typeof HotelRoomType = HotelRoomType;

  isUnregistered: boolean = false;

  get firstname(): AbstractControl {
    return this.formGroup.get('firstname');
  }

  get lastname(): AbstractControl {
    return this.formGroup.get('lastname');
  }

  get country(): AbstractControl {
    return this.formGroup.get('country');
  }

  get email(): AbstractControl {
    return this.formGroup.get('email');
  }

  get phone(): AbstractControl {
    return this.formGroup.get('phone');
  }

  get bwfId(): AbstractControl {
    return this.formGroup.get('bwfId');
  }

  get organization(): AbstractControl {
    return this.formGroup.get('organization');
  }

  get roles(): AbstractControl {
    return this.formGroup.get('roles');
  }

  get accessTypesOfPerson(): FormArray {
    return this.formGroup.controls.accessTypesOfPerson as FormArray;
  }

  get gender(): AbstractControl {
    return this.formGroup.get('gender');
  }

  get birthdate(): AbstractControl {
    return this.formGroup.get('birthdate');
  }

  get isParkingCardNeeded(): AbstractControl {
    return this.formGroup.get('isParkingCardNeeded');
  }

  get isParkingCardIssued(): AbstractControl {
    return this.formGroup.get('isParkingCardIssued');
  }

  get isVisaNeeded(): AbstractControl {
    return this.formGroup.get('isVisaNeeded');
  }

  get isBadgeNeeded(): AbstractControl {
    return this.formGroup.get('isBadgeNeeded');
  }

  get isHotelNeeded(): AbstractControl {
    return this.formGroup.get('isHotelNeeded');
  }

  get isTransportNeededOnArrival(): AbstractControl {
    return this.formGroup.get('isTransportNeededOnArrival');
  }

  get isTransportNeededOnDeparture(): AbstractControl {
    return this.formGroup.get('isTransportNeededOnDeparture');
  }

  get isFreeHotel(): AbstractControl {
    return this.formGroup.get('isFreeHotel');
  }

  get isHotelInvoicePaid(): AbstractControl {
    return this.formGroup.get('isHotelInvoicePaid');
  }

  // get isPcrTestReceived(): AbstractControl {
  //   return this.formGroup.get('isPcrTestReceived');
  // }

  // get isAntigenTestReceived(): AbstractControl {
  //   return this.formGroup.get('isAntigenTestReceived');
  // }

  // get pcrTestReceivedAt(): AbstractControl {
  //   return this.formGroup.get('pcrTestReceivedAt');
  // }

  // get antigenTestReceivedAt(): AbstractControl {
  //   return this.formGroup.get('antigenTestReceivedAt');
  // }

  get paidAt(): AbstractControl {
    return this.formGroup.get('paidAt');
  }

  get invoiceNumber(): AbstractControl {
    return this.formGroup.get('invoiceNumber');
  }

  get preferredHotelRoomType(): AbstractControl {
    return this.formGroup.get('preferredHotelRoomType');
  }

  get arrivesAt(): AbstractControl {
    return this.formGroup.get('arrivesAt');
  }

  get departsAt(): AbstractControl {
    return this.formGroup.get('departsAt');
  }

  get comment(): AbstractControl {
    return this.formGroup.get('comment');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private personService: PersonService,
    private tournamentRoleService: TournamentRoleService,
    private tournamentFieldsService: PersonTournamentFieldsService,
    private tournamentService: TournamentService,
    private organizationService: OrganizationService,
    private roleService: RoleService,
    private badgeService: BadgeService,
    private accessTypeService: AccessTypeService
  ) { }

  async ngOnInit(): Promise<void> {
    this.personId = this.route.snapshot.params.personId;
    this.tournaments = await this.tournamentService.get({ sort: 'start,DESC' }).toPromise();
    const tournamentId = this.route.parent.snapshot.params.tournamentId || this.tournaments[0].id;
    this.tournament = this.tournaments.filter(x => x.id === tournamentId)[0];

    await this.loadOrganizations();
    await this.loadRoles();
    await this.loadAccessTypes();

    if (!this.personId) {
      return;
    }

    this.badgeService.get({ join: ['accessTypes'], filter: [`personId||eq||${this.personId}`, `tournamentId||$eq||${tournamentId}`] }).subscribe((res) => {
        this.badges = res;
        this.setActiveAccessTypes();
    });

    const person = await this.personService.getOne(this.personId, { join: ['organization'] }).toPromise();
    // Filter all not needed roles for this tournament
    person.tournamentRoles = person.tournamentRoles.filter(tournamentRole => tournamentId === tournamentRole.tournamentId);
    this.humanFriendlyId = person.humanFriendlyId;
    this.avatarUrl = person.avatarUrl;
    this.rolesOfPerson = person.tournamentRoles;
    // take fields of newest tournament
    this.tournamentFields = person.tournamentFields.filter(x => x.tournamentId === this.tournament.id)[0];

    this.setFields(person);
    this.setTournamentFields(this.tournamentFields);

    this.isTournamentDetailsSectionVisible = person.tournamentRoles?.length > 0;
    this.isUnregistered = person.tournamentRoles.some(role => role.roleId === RoleIdentifier.UNREGISTERED);
  }

  setFields(person): void {
    this.firstname.setValue(person.firstname);
    this.lastname.setValue(person.lastname);
    this.country.setValue(person.countryCode);
    this.email.setValue(person.email);
    this.phone.setValue(person.phone);
    this.bwfId.setValue(person.bwfId);
    this.gender.setValue(person.gender);
    this.birthdate.setValue(person.birthdate);
    this.organization.setValue(person.organization);
    this.roles.setValue(person.tournamentRoles.map(x => x.roleId));
  }

  setTournamentFields(tournamentFields): void {
    if (tournamentFields) {
      this.arrivesAt.setValue(tournamentFields.arrivesAt);
      this.departsAt.setValue(tournamentFields.departsAt);
      this.isParkingCardNeeded.setValue(tournamentFields.isParkingCardNeeded);
      this.isParkingCardIssued.setValue(tournamentFields.isParkingCardIssued);
      this.isVisaNeeded.setValue(tournamentFields.isVisaNeeded);
      this.isBadgeNeeded.setValue(tournamentFields.isBadgeNeeded);
      this.isHotelNeeded.setValue(tournamentFields.isHotelNeeded);
      this.isFreeHotel.setValue(tournamentFields.isFreeHotel);
      this.isHotelInvoicePaid.setValue(tournamentFields.isHotelInvoicePaid);
      this.invoiceNumber.setValue(tournamentFields.invoiceNumber);
      this.paidAt.setValue(tournamentFields.paidAt);
      this.isTransportNeededOnArrival.setValue(tournamentFields.isTransportNeededOnArrival);
      this.isTransportNeededOnDeparture.setValue(tournamentFields.isTransportNeededOnDeparture);
      this.comment.setValue(this.tournamentFields.comment);
      this.preferredHotelRoomType.setValue(this.tournamentFields.preferredHotelRoomType);
      // this.isPcrTestReceived.setValue(tournamentFields.isPcrTestReceived);
      // this.isAntigenTestReceived.setValue(tournamentFields.isAntigenTestReceived);
      // this.pcrTestReceivedAt.setValue(tournamentFields.pcrTestReceivedAt);
      // this.antigenTestReceivedAt.setValue(tournamentFields.antigenTestReceivedAt);
    }
  }

  setActiveAccessTypes(): void {
    this.badges[0].accessTypes?.forEach(selectedAccessType => {
      let activePosition = this.accessTypes.findIndex(x => x.id === selectedAccessType.id);
      this.accessTypesOfPerson.at(activePosition).setValue(true)
    });
  }

  async loadOrganizations(): Promise<void> {
    this.organizations = await this.organizationService.get({ sort: ['type,ASC', 'name,ASC'] }).toPromise();
  }

  async loadRoles(): Promise<void> {
    this.rolesOfTournament = await this.roleService.get(
      {
        filter: [`tournamentId||$eq||${this.tournament.id}`],
        or: `tournamentId||$isnull`,
        sort: 'name,ASC'
      })
      .toPromise();
  }

  async loadAccessTypes(): Promise<void> {
    const response = await this.accessTypeService.get().toPromise();
    this.accessTypes = response;
    for (const accessType of this.accessTypes) {
      this.accessTypesOfPerson.push(new FormControl(false));
    }
  }

  async updateAccessTypes(badgeId): Promise<void> {
    for (let i = 0; i < this.accessTypes.length; i++) {
      const accessType = this.accessTypes[i];
      const isSelected = this.accessTypesOfPerson.at(i).getRawValue() ? true : false;
      let wasSelected = false;
      if (this.badges.length === 1) {
        wasSelected = this.badges[0].accessTypes?.some(x => x.id === accessType.id);
      }

      if (isSelected && !wasSelected) {
        await this.badgeService.addAccessType(badgeId, accessType.id).toPromise();
      } else if (!isSelected && wasSelected) {
        await this.badgeService.removeAccessType(badgeId, accessType.id).toPromise();
      }
    }
  }

  async updateBadge(): Promise<void> {
    if (this.badges.length === 0 && this.isBadgeNeeded.value === true) {
      //Create badge
      const badge = {
        personId: this.personId,
        tournamentId: this.tournament.id
      };

      this.badgeService.create(badge).subscribe(
        (res) => {
          this.badges.push(res);
          this.updateAccessTypes(res.id);
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    } else if (this.badges.length > 0 && this.isBadgeNeeded.value === false) {
      //Remove badge and any associated accesstypes
      await this.badgeService.delete(this.badges[0].id).toPromise();
    } else if (this.badges.length > 0) {
      this.updateAccessTypes(this.badges[0].id);
    }
  }

  async updateRoles(): Promise<void> {
    let newRoles = JSON.parse(JSON.stringify(this.rolesOfPerson));
    for (const roleId of this.roles.value) {
      if (this.rolesOfPerson.filter(x => x.roleId === roleId).length === 0) {
        const newRole = await this.tournamentRoleService.create(
          {
            tournamentId: this.tournament.id,
            roleId: roleId,
            personId: this.personId
          }).toPromise();
          newRoles.push(newRole);
      }
    }

    for (const tournamentRole of this.rolesOfPerson) {
      if (!this.roles.value.includes(tournamentRole.roleId)) {
        await this.tournamentRoleService.delete(tournamentRole.id).toPromise();
        newRoles = this.rolesOfPerson.filter(x => x.id !== tournamentRole.id);
      }
    }
    console.log(newRoles);
    this.rolesOfPerson = newRoles;
  }

  async updateTournamentFields(): Promise<void> {
    const tournamentFields: PersonTournamentFields = {
      id: this.tournamentFields?.id,
      personId: this.personId,
      tournamentId: this.tournament.id,
      isParkingCardNeeded: this.isParkingCardNeeded.value,
      isParkingCardIssued: this.isParkingCardIssued.value,
      isVisaNeeded: this.isVisaNeeded.value,
      isBadgeNeeded: this.isBadgeNeeded.value,
      isHotelNeeded: this.isHotelNeeded.value,
      isTransportNeededOnArrival: this.isTransportNeededOnArrival.value,
      isTransportNeededOnDeparture: this.isTransportNeededOnDeparture.value,
      isFreeHotel: this.isFreeHotel.value,
      isHotelInvoicePaid: this.isHotelInvoicePaid.value,
      paidAt: this.paidAt.value,
      invoiceNumber: this.invoiceNumber.value,
      arrivesAt: this.arrivesAt.value,
      departsAt: this.departsAt.value,
      comment: this.comment.value,
      // isPcrTestReceived: this.isPcrTestReceived.value,
      // isAntigenTestReceived: this.isAntigenTestReceived.value,
      // pcrTestReceivedAt: this.pcrTestReceivedAt.value,
      // antigenTestReceivedAt: this.antigenTestReceivedAt.value,
      preferredHotelRoomType: this.preferredHotelRoomType.value,
    };

    if (tournamentFields.id) {
      this.tournamentFields = await this.tournamentFieldsService.update(tournamentFields, tournamentFields.id).toPromise();
    } else {
      this.tournamentFields = await this.tournamentFieldsService.create(tournamentFields).toPromise();
    }
  }

  async submit(): Promise<void> {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    const person: Person = new Person({
      firstname: this.firstname.value,
      lastname: this.lastname.value,
      countryCode: this.country.value,
      email: this.email.value || null,
      phone: this.phone.value || null,
      organization: new Organization({ id: this.organization.value }) || null,
      bwfId: this.bwfId.value || null,
      gender: Number(this.gender.value),
      birthdate: this.birthdate.value || null,
      image: this.image || null,
    });

    if (this.personId) {
      await this.personService.update(person, this.personId)
        .toPromise()
        .then(async (res) => {
          if (person.image) {
            await this.personService.uploadImage(person.image, this.personId);
          }
          await this.updateRoles();
          await this.updateTournamentFields();
          await this.updateBadge();
        })
        .catch(err => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(err);
        })
        .finally(() => {
          this.submitting = false;
        });

    } else {
      await this.personService.create(person)
        .toPromise()
        .then(async (res) => {
          this.personId = res.id;
          if (person.image) {
            await this.personService.uploadImage(person.image, this.personId);
          }
          await this.updateRoles();
          await this.updateTournamentFields();
          await this.updateBadge();
        })
        .catch(err => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    }

    if (!this.error && this.goBackOnSubmit) {
      this.location.back();
    }
  }

  compareWith(item, selected): boolean {
    return item.id === selected.id;
  }

  async onUnregister(): Promise<void> {
    if (this.rolesOfTournament) {
      const newRoles = [RoleIdentifier.UNREGISTERED, ...this.roles?.value];
      console.log(newRoles);
      this.roles.setValue(newRoles);
      this.rolesOfPerson
      await this.updateRoles();
      this.isUnregistered = true;
    }
  }

  async onRegisterAgain(): Promise<void> {
    const roles: [] = this.roles?.value;
    const newRoles = roles.filter(role => role !== RoleIdentifier.UNREGISTERED);
    this.roles.setValue(newRoles);
    await this.updateRoles();
    this.isUnregistered = false;
  }
}
