<image-cropper class="text-left" style="display:table-cell !important;"
[imageFile]="this.file"
[roundCropper]="true"
[containWithinAspectRatio]="false"
[onlyScaleDown]="true"
format="jpeg"
(imageCropped)="imageCropped($event)"
(loadImageFailed)="loadImageFailed()">
</image-cropper>

<ngx-dropzone class="inline-block w-40  overflow-hidden h-40 mb-5"
  [style.background-image]="!croppedImage ? this.avatarBackground : this.cropperBackground"
  (change)="onSelect($event)" [multiple]="'false'" accept="image/png, image/jpeg, image/jpg">
  <ngx-dropzone-label>Drop file here or click to open dialog</ngx-dropzone-label>
</ngx-dropzone>
<p *ngIf="this.imageLoadFailedMessage" class="mt-2 text-sm text-red-600" id="avatar-error">{{this.imageLoadFailedMessage}}</p>